@let hasMoreThanOneRole = availableRoles().length > 0;

<div class="sub-header">
	<nav class="sub-navbar">
		<div ngbDropdown data-cy="role-dropdown">
			<a style="cursor: pointer" ngbDropdownToggle [class.disable-click]="!hasMoreThanOneRole"
				>{{ currentRole().label }}

				@if (hasMoreThanOneRole) {
					<i class="arrow down"></i>
				}
			</a>

			<ul ngbDropdownMenu class="jbl-dropdown avatar-menu">
				<li class="main">SWTICH ROLE</li>

				@for (role of availableRoles(); track $index) {
					<li ngbDropdownItem class="jbl-dropdown-item" (click)="switchRole(role)">{{ role.label }}</li>
				}
			</ul>
		</div>

		<!-- <div class="vertical-line"></div> -->

		@if (!isClosed() && showInviteButtons()) {
			<button *ifAllowOwnersInvites class="invite-btn" (click)="openInviteOwnersModal()">Invite Owner</button>
			<button *ifAllowContributorsInvites class="invite-btn" (click)="openInviteContributorsModal()">Invite Contributor</button>
		}
	</nav>
</div>
