import { Component, signal } from '@angular/core';
import { Router } from '@angular/router';

@Component({
	selector: 'mi-jbl-not-permitted',
	templateUrl: './not-permitted.component.html',
	styleUrls: ['./not-permitted.component.scss'],
	standalone: true
})
export class JblNotPermittedComponent {
	public content = signal({
		subtitle: 'It seems like this page is not allowed for your account. To proceed with it, please request the access.',
		img: 'assets/img/various/shield.svg',
		redirectUrl: '',
		title: null,
		button: null
	});

	constructor(private router: Router) {}

	public onRedirect(): void {
		const url = this.content()?.redirectUrl;

		if (url) {
			if (url?.includes('http')) {
				window.location.href = url;
				return;
			} else {
				void this.router.navigate([url]);
				return;
			}
		}

		window.location.href = 'https://jabilit.service-now.com/sp';
	}
}
