import { Component, OnInit, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { AppSyncChannels } from '@interfaces/app-sync.interface';
import { AppSyncEventService } from '@services/appsync.service';

@Component({
	selector: 'mi-root',
	standalone: true,
	imports: [RouterOutlet],
	template: `<main class="app-container">
		<router-outlet></router-outlet>
	</main>`
})
export class AppComponent implements OnInit {
	private appSync = inject(AppSyncEventService);

	ngOnInit() {
		this.appSync.connect();

		this.appSync.onConnection().subscribe(() => {
			this.appSync.subscribeToChannel(AppSyncChannels.DEFAULT);
		});
	}
}
