import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, importProvidersFrom } from '@angular/core';
import { BearerTokenInterceptor } from '@core/interceptors/bearer-token.interceptor';
import { InitService } from '@core/services/init/init.service';
import { JblIconModule, JblThemeModule } from '@jbl/foundation';

export function provideInitializers() {
	return [
		{
			provide: APP_INITIALIZER,
			multi: true,
			deps: [InitService],
			useFactory: (initService: InitService) => initService.initializeApp()
		}
	];
}

export function provideInterceptors() {
	return [{ provide: HTTP_INTERCEPTORS, useClass: BearerTokenInterceptor, multi: true }];
}

export function provideCommonJbl() {
	return importProvidersFrom([JblThemeModule, JblIconModule]);
}
