import { Component, signal } from '@angular/core';
import { Router } from '@angular/router';

@Component({
	selector: 'mi-jbl-not-found',
	templateUrl: './not-found.component.html',
	styleUrls: ['./not-found.component.scss']
})
export class JblNotFoundComponent {
	public content = signal({
		title: 'Sorry, page was not found',
		subtitle: 'It seems like this page is not exist in this app or it was removed',
		button: 'Return to the Homepage',
		img: 'assets/img/various/no-data.png',
		redirectUrl: '/'
	});

	constructor(private router: Router) {}

	public onRedirect(): void {
		const url = this.content().redirectUrl;

		if (url) {
			if (url?.includes('http')) {
				window.location.href = url;
				return;
			} else {
				void this.router.navigate([url]);
				return;
			}
		}

		void this.router.navigate(['/']);
	}
}
