<section class="error-page">
	<h1 class="jbl-heading-3 jbl-700 error-page-heading">
		{{ content().title || "Sorry, you don't have access to view that." }}
	</h1>

	@if (content().subtitle) {
		<h3 class="jbl-heading-6 jbl-500 wts-center error-page-subheading">
			{{ content().subtitle }}
		</h3>
	}

	@if (content().img) {
		<img class="error-page-img" [src]="content().img" />
	}

	<button class="jbl-btn jbl-btn-sm jbl-btn-primary error-page-btn" (click)="onRedirect()">
		{{ content().button || 'Request Access' }}
	</button>
</section>
