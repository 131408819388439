import { bootstrapApplication } from '@angular/platform-browser';
import { environment } from '@environment';
import {
	CellStyleModule,
	ClientSideRowModelModule,
	ColumnAutoSizeModule,
	DateFilterModule,
	GridStateModule,
	LicenseManager,
	ModuleRegistry,
	NumberFilterModule,
	PinnedRowModule,
	provideGlobalGridOptions,
	RowApiModule,
	RowGroupingModule,
	RowSelectionModule,
	ServerSideRowModelApiModule,
	ServerSideRowModelModule,
	SetFilterModule,
	StatusBarModule,
	TextFilterModule,
	TooltipModule,
	ValidationModule
} from 'ag-grid-enterprise';
import { AppComponent } from './app/app.component';
import { appConfig } from './app/app.config';

LicenseManager.setLicenseKey(environment.agGridLicenseKey);
ModuleRegistry.registerModules([
	ClientSideRowModelModule,
	RowGroupingModule,
	ServerSideRowModelModule,
	SetFilterModule,
	StatusBarModule,
	ValidationModule,
	TextFilterModule,
	NumberFilterModule,
	CellStyleModule,
	TooltipModule,
	GridStateModule,
	DateFilterModule,
	RowApiModule,
	ColumnAutoSizeModule,
	PinnedRowModule,
	RowSelectionModule,
	ServerSideRowModelApiModule
]);
provideGlobalGridOptions({ theme: 'legacy' });

bootstrapApplication(AppComponent, appConfig)
	.then(() => console.info('[bootstrapApplication] App bootstrapped'))
	.catch(err => console.error(err));
